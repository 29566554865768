import { Paper, PaperProps } from '@mui/material';

export const MyPaper = ({ sx, children }: PaperProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        background: 'custom.bg.3',
        borderRadius: '6px',
        padding: '10px 8px',
        ...sx,
      }}>
      {children}
    </Paper>
  );
};

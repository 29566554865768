import { Fragment, useEffect } from 'react';
import { Box, keyframes, Typography } from '@mui/material';
import TournamentsCup from 'src/assets/icons/cup.svg';
import { getColorOfCup } from 'src/_pages/tournament/tournament-leaderboard-v2/leader-board-top-item';
import _ from 'lodash';
import { ordinalSuffixOf } from 'src/utils/helpers/number';

interface AnimatedTypographyProps {
  text: string;
  isFallingIn: boolean;
  rank: number;
  onAnimationEnd?: () => void;
}

const fallOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(120%);
    opacity: 0;
  }
`;

const fallIn = keyframes`
  0% {
    transform: translateY(-120%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const getFallTextStyles = (
  index: number,
  totalBlocks: number,
  isFallingIn: boolean,
  isCup: boolean = false,
) => {
  const delay = isCup
    ? isFallingIn
      ? 0.1 * totalBlocks
      : 0.1 * (totalBlocks - index)
    : isFallingIn
      ? 0.1 * index
      : 0.1 * (totalBlocks - index);

  return {
    display: 'inline-block',
    animation: `${isFallingIn ? fallIn : fallOut} 0.8s forwards ease-in-out`,
    animationDelay: `${delay - 0.4}s`,
  };
};

export const AnimatedTypography = ({
  text,
  isFallingIn,
  rank,
  onAnimationEnd,
}: AnimatedTypographyProps) => {
  const words = text?.split(' ') || [];

  useEffect(() => {
    const totalDuration = 0.1 * (words?.length + 1) + 0.4;

    const timer = setTimeout(() => {
      onAnimationEnd?.();
    }, totalDuration * 1000);

    return () => clearTimeout(timer);
  }, [isFallingIn, words?.length, onAnimationEnd]);

  const color = getColorOfCup(rank);

  return (
    <Typography
      sx={{
        fontSize: 14,
        lineHeight: 1,
        fontWeight: 500,
        display: 'inline-flex',
        color: color,
      }}>
      {_.map(words, (word, index) => (
        <Fragment key={index}>
          <Box sx={getFallTextStyles(index, words.length + 1, isFallingIn)}>
            {index === 0 ? ordinalSuffixOf(Number(word)) : word}
          </Box>
          {index < words.length - 1 && (
            <Box sx={{ display: 'inline-block', width: '8px' }} />
          )}
        </Fragment>
      ))}
      {words && words.length > 0 && rank < 4 && (
        <Box
          component="span"
          sx={{
            ...getFallTextStyles(
              words.length + 1,
              words.length + 1,
              isFallingIn,
              true,
            ),
            ml: 1,
            '& > svg': {
              fill: color,
            },
          }}>
          <TournamentsCup />
        </Box>
      )}
    </Typography>
  );
};

import { BoxProps, Typography } from '@mui/material';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';
import { getTimeStatusToLabel } from 'src/utils/helpers/tournament';

const statusToStyles = {
  [TOUR_TIME_STATUS.UPCOMING]: {
    bgcolor: '#A5A5A5',
    color: '#121313',
  },
  [TOUR_TIME_STATUS.LIVE]: {
    bgcolor: 'custom.status.green',
  },
  [TOUR_TIME_STATUS.ENDED]: {
    bgcolor: 'custom.content.2',
    color: 'custom.bg.1',
  },
} as Record<TOUR_TIME_STATUS, { bgcolor: string; color: string }>;

export const TournamentBadge: React.FCC<
  {
    status?: TOUR_TIME_STATUS;
  } & BoxProps
> = (props) => {
  const { status = TOUR_TIME_STATUS.UPCOMING, ...boxProps } = props;

  return (
    <Typography
      variant="caption"
      component="span"
      {...boxProps}
      sx={{
        borderRadius: 1,
        mt: 1,
        top: 0,
        width: '80px',
        textAlign: 'center',
        padding: '8px',
        fontWeight: 700,
        letterSpacing: '0.4px',
        ...statusToStyles[status],
        ...boxProps?.sx,
      }}>
      {getTimeStatusToLabel(status)}
    </Typography>
  );
};

import { Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import React from 'react';
import { MyPaper } from 'src/_pages/tournament/tournament-leaderboard-v2/common';
import { useLocale } from 'src/hooks/use-locale';
import { ordinalSuffixOf } from 'src/utils/helpers/number';
import { TournamentRank } from 'src/utils/types/tournament';

interface LeaderBoardItemProps {
  data: TournamentRank;
}
const LeaderBoardItem = ({ data }: LeaderBoardItemProps) => {
  const { FORMAT } = useLocale();
  const { rank, username, score } = data;
  const ordinalNumber = ordinalSuffixOf(rank);
  return (
    <MyPaper>
      <Stack direction="row">
        <Typography fontWeight={500} color="custom.content.4">
          {ordinalNumber}
        </Typography>
        <Typography
          noWrap
          sx={{ flex: 1, paddingX: '12px', color: 'custom.content.2' }}>
          {username}
        </Typography>
        <Typography fontWeight={500}>
          {new BigNumber(score).toFormat(2, {
            ...FORMAT,
            prefix: '$',
          })}
        </Typography>
      </Stack>
    </MyPaper>
  );
};

export default LeaderBoardItem;

/* eslint-disable lingui/no-single-variables-to-translate */
import { Box, NoSsr, Typography } from '@mui/material';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';

const renderTime = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
) => {
  if (days > 0) {
    return `${days}d ${hours}h`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  return `${minutes}m ${seconds}s`;
};

export const TournamentTimer: React.FCC<{
  status?: TOUR_TIME_STATUS;
  date?: moment.Duration;
}> = ({ date, status = TOUR_TIME_STATUS.UPCOMING, ...rest }) => {
  if (status === TOUR_TIME_STATUS.ENDED) return null;

  const days = date?.days() ?? 0;
  const hours = date?.hours() ?? 0;
  const minutes = date?.minutes() ?? 0;
  const seconds = date?.seconds() ?? 0;

  return (
    <NoSsr>
      <Box
        sx={{
          color: 'custom.content.4',
          fontWeight: 500,
          textAlign: 'center',
          position: 'absolute',
          right: '8px',
          bottom: '8px',
          borderRadius: 1,
          padding: '6px 14px',
          bgcolor: 'rgba(34, 34, 34, 0.7)',
        }}>
        <Box sx={{ display: 'inline-block' }}>
          <Typography
            sx={{
              color:
                days <= 0 && hours <= 0 && minutes < 5
                  ? 'custom.status.red'
                  : '',
            }}
            component="span">
            {renderTime(days, hours, minutes, seconds)}
          </Typography>
        </Box>
      </Box>
    </NoSsr>
  );
};

import Box, { BoxProps } from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import * as React from 'react';
import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

const mobile = QueryMapping.mobile;

const TAB_HEIGHT = 34;
type Tab = {
  title: React.ReactNode;
  value?: string;
};
type ScrollableTabsProps = TabsProps & {
  tabs: Tab[];
  containerProps?: BoxProps;
  tabProps?: TabProps | ((val: Tab) => TabProps<any>);
  overflowItems?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  tabsRoot: {
    padding: '4px',
    alignItems: 'center',
    borderRadius: '20px !important',
  },
  tabRoot: {
    minHeight: TAB_HEIGHT,
    color: theme.custom.content[2],
    [mobile]: {
      fontSize: '14px',
    },
  },
  overflowTabsRoot: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    left: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    overflowX: 'scroll',
    position: 'relative',
    right: '50%',
    scrollSnapType: 'x mandatory',
    scrollbarWidth: 'none',
    width: '100vw',
    '& > div': {
      padding: '0 10px',
    },
  },
}));

export default function ScrollableTabs({
  tabs,
  containerProps,
  tabProps,
  overflowItems,
  ...rest
}: ScrollableTabsProps) {
  const { sx, ...restOfContainerProps } = containerProps ?? {};

  const { classes, cx } = useStyles();
  return (
    <Box
      sx={{
        display: 'inline-grid',
        bgcolor: 'background.paper',
        width: '100%',
        borderRadius: '6px',
        ...sx,
      }}
      {...restOfContainerProps}>
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        classes={{
          root: overflowItems ? classes.overflowTabsRoot : classes.tabsRoot,
        }}
        TabIndicatorProps={{
          sx: {
            bgcolor: 'primary.main',
            height: '100%',
            color: 'black',
            borderRadius: '6px',
          },
        }}
        {...rest}>
        {tabs.map((tab, index) => {
          const props =
            typeof tabProps === 'function' ? tabProps(tab) : tabProps;

          return (
            <Tab
              {...props}
              key={index}
              label={tab.title}
              value={tab.value}
              classes={{
                root: classes.tabRoot,
              }}
              sx={{
                minWidth: '64px',
                zIndex: 2,
                fontWeight: 'bold',
                flex: 1,
                padding: '0 8px',
                fontSize: '14px',
                [QueryMapping.mobile]: {
                  fontSize: '12px',
                },
                '&.Mui-selected': {
                  color: 'common.black',
                },
                ...props?.sx,
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

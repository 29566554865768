import { Trans } from '@lingui/macro';
import { Stack, Theme, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import React from 'react';
import { useLocale } from 'src/hooks/use-locale';
import { ordinalSuffixOf } from 'src/utils/helpers/number';
import { TournamentRank } from 'src/utils/types/tournament';
import CupIcon from 'src/assets/icons/cup.svg';

interface LeaderBoardTopItemProps {
  data: TournamentRank | null;
  order: number;
}

export const getColorOfCup = (rank: number | undefined | null) => {
  if (rank === 1) return (theme: Theme) => theme.palette.custom.status.gold;
  if (rank === 2) return (theme: Theme) => theme.palette.custom.status.silver;
  if (rank === 3) return (theme: Theme) => theme.palette.custom.status.copper;
  return '';
};

const LeaderBoardTopItem = ({ data, order }: LeaderBoardTopItemProps) => {
  const { FORMAT } = useLocale();

  const ordinalNumber = ordinalSuffixOf(order);
  const color = getColorOfCup(order);
  return (
    <Stack sx={{ flex: 1, width: 0 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          padding: 0.5,
          borderRadius: '6px',
          backgroundColor: 'custom.bg.1',
          alignItems: 'center',
          justifyContent: 'center',
          '& > svg': {
            fill: color,
          },
        }}>
        <Typography
          variant="16"
          color={color}
          fontWeight={800}
          textAlign="center">
          {ordinalNumber}
        </Typography>
        <CupIcon />
      </Stack>
      {_.isNull(data) ? (
        <Typography textAlign="center" mt={1}>
          <Trans id="leaderboard.top3.not_found">
            Could it be you? Bet now!
          </Trans>
        </Typography>
      ) : (
        <>
          <Typography textAlign="center" mt={1}>
            {new BigNumber(data.score).toFormat(2, { ...FORMAT, prefix: '$' })}
          </Typography>
          <Typography
            noWrap
            textAlign="center"
            mt={0.5}
            color="custom.content.2">
            {data.username}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default LeaderBoardTopItem;

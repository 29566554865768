import { Trans } from '@lingui/macro';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import _, { List } from 'lodash';
import React, { useState } from 'react';
import { getMyTourRank, getTourLeaderboard } from 'src/api/tournament';
import { useSocketEventFn } from 'src/hooks/socket/use-socket-fn';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { getMyTourRankKey, getTourLeaderboardKey } from 'src/utils/helpers/swr';
import { TournamentScoreMessage } from 'src/utils/types/socket-type';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { loggedAtom, userIdAtom } from 'src/store/auth';
import { useQuery } from 'src/hooks/use-query';
import UserPosition from 'src/_pages/tournament/tournament-leaderboard-v2/user-postion';
import ArrowDown from 'src/assets/icons/arrow-down.svg';
import LeaderBoardTopItem from 'src/_pages/tournament/tournament-leaderboard-v2/leader-board-top-item';
import { MyPaper } from 'src/_pages/tournament/tournament-leaderboard-v2/common';
import LeaderBoardItem from 'src/_pages/tournament/tournament-leaderboard-v2/leader-board-item';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';

const takeItems = <T,>(
  arr: List<T> | null | undefined,
  n: number,
): (T | null)[] => {
  const result = _.take(arr, n);
  // Fill with null if less than n items
  return _.concat(result, _.fill(Array(n - result.length), null));
};

const TOP_USERS = 3;
interface TournamentLeaderBoardProps {
  status: TOUR_TIME_STATUS;
}

const TournamentLeaderBoard = ({ status }: TournamentLeaderBoardProps) => {
  const logged = useAtomValue(loggedAtom);
  const userId = useAtomValue(userIdAtom);
  const { id } = useQuery(['id']);
  const { myRank, leaderboard } = useTourLeaderboard({ userId, id, size: 10 });
  const [showAll, setShowAll] = useState(false);

  const topUser = takeItems(leaderboard?.items, TOP_USERS);
  const others = _.slice(leaderboard?.items, TOP_USERS);
  const isTopUser = myRank?.rank && myRank?.rank <= TOP_USERS;

  return (
    <div>
      <Typography variant="18">
        <Trans id="tournament.leader_board.title">Leaderboard</Trans>
      </Typography>
      <MyPaper
        sx={{
          padding: 1,
          mt: '8px',
        }}>
        <Stack direction="row" spacing={2} alignItems="stretch">
          {_.map(topUser, (item, idx) => (
            <LeaderBoardTopItem
              key={item?.userId}
              data={item}
              order={idx + 1}
            />
          ))}
        </Stack>
      </MyPaper>
      {logged && !isTopUser && <UserPosition data={myRank} status={status} />}

      {others.length > 0 && (
        <>
          <Accordion
            expanded={showAll}
            sx={{
              '&:before': {
                display: 'none',
              },
              margin: '0px !important',
              backgroundColor: 'transparent',
            }}>
            <AccordionSummary
              style={{ display: 'none' }}
              aria-controls="leaderboard-content"
              id="leaderboard-header"></AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0',
              }}>
              {showAll && (
                <Stack spacing={1} mt={1}>
                  {_.map(others, (item) => (
                    <LeaderBoardItem data={item} key={item?.userId} />
                  ))}
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
          <Stack direction="row-reverse" mt="20px">
            <Stack
              direction="row"
              spacing={1}
              sx={{ cursor: 'pointer', ml: 'auto' }}
              onClick={() => setShowAll((prev) => !prev)}>
              <Typography>
                {!showAll ? (
                  <Trans id="common.show_all">Show all</Trans>
                ) : (
                  <Trans id="common.hide_all">Hide all</Trans>
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  transform: 'rotate(-180deg)',
                  transition: (theme) =>
                    theme.transitions.create(['transform']),
                  '&.rotate': { transform: 'rotate(0deg)' },
                }}
                className={showAll ? 'rotate' : ''}>
                <ArrowDown />
              </Box>
            </Stack>
          </Stack>
        </>
      )}
    </div>
  );
};

export default TournamentLeaderBoard;

export const useTourLeaderboard = (props: {
  userId?: number;
  id?: string;
  size?: number;
}) => {
  const { userId, id, size } = props;

  const { data: myRank, mutate } = useSWR(
    userId && id ? getMyTourRankKey(id) : null,
    ([, id]) => getMyTourRank(id),
    { keepPreviousData: false },
  );

  const { data: leaderboard } = useSWR(
    id ? getTourLeaderboardKey(id, size) : null,
    ([, _id, _size]) => getTourLeaderboard(_id, _size, 1),
  );

  useSocketEventFn(
    SocketEvent.TournamentScore,
    (msg: TournamentScoreMessage) => {
      const { tournament, userId: _userId, rank, score } = msg;
      if (userId !== _userId || tournament !== id) return;

      mutate((prev) => prev && { rank, score }, {
        revalidate: false,
        populateCache: true,
      });
    },
    Boolean(id),
  );

  return {
    myRank: myRank?.rank && myRank?.score ? myRank : undefined,
    leaderboard,
  };
};

import { useState } from 'react';

export const useAnimatedText = (leaderboardUser: string[]) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isFallingIn, setIsFallingIn] = useState(true);

  const handleTextAnimationEnd = () => {
    if (isFallingIn) {
      setIsFallingIn(false);
    } else {
      setCurrentTextIndex(
        (prevIndex) => (prevIndex + 1) % leaderboardUser.length,
      );
      setIsFallingIn(true);
    }
  };

  return {
    currentTextIndex,
    isFallingIn,
    handleTextAnimationEnd,
  };
};

import { Trans } from '@lingui/macro';
import { Box, Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useLocale } from 'src/hooks/use-locale';
import { ordinalSuffixOf } from 'src/utils/helpers/number';
import TournamentsTapAway from 'src/assets/icons/tournaments-tap-away.svg';
import { MyPaper } from 'src/_pages/tournament/tournament-leaderboard-v2/common';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';

interface UserPositionProps {
  data?: {
    score?: number;
    rank?: number;
  };
  status: TOUR_TIME_STATUS;
}
const UserPosition = ({ data, status }: UserPositionProps) => {
  const { FORMAT } = useLocale();

  if (!data || !data.rank || !data.score)
    return (
      <MyPaper sx={{ mt: 1 }}>
        <Stack direction="row" alignItems="center" spacing="12px">
          <Box
            sx={{
              width: '24px',
              height: '24px',
            }}>
            <TournamentsTapAway width={24} height={24} />
          </Box>
          <Box>
            <Typography fontWeight={700}>
              <Trans id="tournaments.leader_board.no_rank.title">
                Tournaments are free to enter!
              </Trans>
            </Typography>
            <Typography>
              <Trans id="tournaments.leader_board.no_rank.description">
                It’s not too late to dive in and win!
              </Trans>
            </Typography>
          </Box>
        </Stack>
      </MyPaper>
    );

  const { score, rank } = data;
  const ordinalNumber = ordinalSuffixOf(rank);

  return (
    <MyPaper sx={{ mt: 1 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography>
          {status == TOUR_TIME_STATUS.ENDED ? (
            <Trans id="tournament.leader_board.user_position.ended">
              You came {ordinalNumber}
            </Trans>
          ) : (
            <Trans id="tournament.leader_board.user_position.live">
              You're {ordinalNumber}
            </Trans>
          )}
        </Typography>
        <Typography>
          {new BigNumber(score).toFormat(2, {
            ...FORMAT,
            prefix: '$',
          })}
        </Typography>
      </Stack>
    </MyPaper>
  );
};

export default UserPosition;

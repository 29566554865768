import moment from 'moment';
import { TOUR_STATUS, TOUR_TIME_STATUS } from 'src/utils/constants/tournament';
import { convertTime } from 'src/utils/helpers/time';
import { request } from 'src/utils/request';
import { BaseResponse, PaginationResponse } from 'src/utils/types/response';
import {
  TournamentCount,
  TournamentItem,
  TournamentRank,
} from 'src/utils/types/tournament';

const PREFIX = '/tournaments';

export const getOneTournament = async (_id: string) => {
  const { data } = await request.get<BaseResponse<TournamentItem>>(
    `${PREFIX}/detail`,
    {
      params: { _id },
    },
  );

  return data.data;
};

type GetTournamentsParams = {
  page_size: number;
  page_index: number;
  status?: TOUR_STATUS | string;
  timeStatus?: TOUR_TIME_STATUS | string;
  sort_by?: string;
  order_by?: string;
};
export const getTournaments = async (params: GetTournamentsParams) => {
  const {
    page_index,
    page_size,
    sort_by = 'order',
    order_by = 'asc',
    ...rest
  } = params;

  const { data } = await request.get<PaginationResponse<TournamentItem>>(
    `${PREFIX}/all`,
    {
      params: {
        ...rest,
        sort_by,
        order_by,
        size: page_size,
        page: page_index + 1,
      },
    },
  );

  return data.data;
};

export const getHotTournaments = async () => {
  const params = {
    page_index: 0,
    page_size: 3,
    status: TOUR_STATUS.ACTIVE,
    timeStatus: TOUR_TIME_STATUS.LIVE,
  };

  const lives = await getTournaments(params);
  const remain = 3 - lives.meta.total_count;

  if (remain <= 0) {
    return {
      items: lives.items,
      meta: { total_count: 3 },
    };
  }

  const upcomings = await getTournaments({
    ...params,
    page_size: remain,
    timeStatus: TOUR_TIME_STATUS.UPCOMING,
  });

  const total = [...lives.items, ...upcomings.items];

  return {
    items: total,
    meta: { total_count: Math.min(3, total.length) },
  };
};

export const getTourLeaderboard = async (
  tournament: string,
  size?: number,
  page?: number,
) => {
  const { data } = await request.get<PaginationResponse<TournamentRank>>(
    `${PREFIX}/user/leaderboard`,
    { params: { tournament, numberTopUser: size, page } },
  );

  return {
    ...data.data,
    now: convertTime(moment().toISOString()),
  };
};

export const getMyTourRank = async (tournament: string) => {
  const { data } = await request.get<
    BaseResponse<{ score?: number; rank?: number }>
  >(`${PREFIX}/user/detail`, { params: { tournament } });

  return data.data;
};

export const getTournamentCount = async () => {
  const { data } = await request.get<
    BaseResponse<{ upcoming: number; live: number; end: number }>
  >(`${PREFIX}/number-of-each-race`);

  const items: TournamentCount[] = [
    {
      status: TOUR_TIME_STATUS.UPCOMING,
      count: data.data.upcoming,
    },
    {
      status: TOUR_TIME_STATUS.LIVE,
      count: data.data.live,
    },
    {
      status: TOUR_TIME_STATUS.ENDED,
      count: data.data.end,
    },
  ];

  return items;
};

import { Trans } from '@lingui/macro';
import { BN } from './big-number';

export const formatUnit = (
  n: string | number,
  decimals: number,
  decimalPlaces = 6,
) => {
  return new BN(n).div(10 ** decimals).decimalPlaces(decimalPlaces);
};

export function ordinalSuffixOf(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return <Trans id="tournaments.position.first">{i}st</Trans>;
  }
  if (j == 2 && k != 12) {
    return <Trans id="tournaments.position.second">{i}nd</Trans>;
  }
  if (j == 3 && k != 13) {
    return <Trans id="tournaments.position.third">{i}rd</Trans>;
  }
  return <Trans id="tournaments.position.others">{i}th</Trans>;
}

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

import { Trans } from '@lingui/macro';
import { Box, Paper, PaperProps, Stack, Typography } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import React from 'react';
import { subFont } from 'src/assets/styles/fonts';
import { AspectRatio } from 'src/components/core/aspect-ratio';
import { MyLink } from 'src/components/core/mine/my-link';
import { useLocale } from 'src/hooks/use-locale';
import { useSyncTimer } from 'src/hooks/use-timer';
import { CRYPTO_DP } from 'src/utils/constants/common-constant';
import { getIconSrc } from 'src/utils/constants/icon-coins';
import { Paths } from 'src/utils/constants/path';
import { BN } from 'src/utils/helpers/big-number';
import { calcTourStatus, getCountTime } from 'src/utils/helpers/tournament';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { TournamentItem as _TournamentItem } from 'src/utils/types/tournament';
import { TournamentBadge } from '../tournament-badge';
import { TournamentTimer } from '../tournament-timer';
import { TOUR_TIME_STATUS } from 'src/utils/constants/tournament';
import TournamentsEmail from 'src/assets/icons/email.svg';
import { useTourLeaderboard } from 'src/_pages/tournament/tournament-leaderboard-v2';
import { useAtomValue } from 'jotai';
import { profileAtom, userIdAtom } from 'src/store/auth';
import { ordinalSuffixOf } from 'src/utils/helpers/number';
import _ from 'lodash';
import { useAnimatedText } from 'src/hooks/use-animated-text';
import { AnimatedTypography } from 'src/_pages/tournament/tournament-item/animated-type-graphy';

export const TournamentItem: React.FCC<
  {
    data?: _TournamentItem;
    readonly?: boolean;
    useNexImage?: boolean;
    nextImageProps?: ImageProps;
  } & PaperProps
> = ({ data, readonly, useNexImage, nextImageProps, ...props }) => {
  const userId = useAtomValue(userIdAtom);
  const username = useAtomValue(profileAtom)?.username;
  const { myRank, leaderboard } = useTourLeaderboard({
    userId,
    id: data?._id,
    size: 10,
  });
  const status = calcTourStatus(data);

  const leaderboardUser = _.chain(leaderboard?.items)
    .take(3)
    .map((item) => `${item.rank} ${item.username ?? 'anonymous'}`)
    .value();

  const userRank = myRank?.rank ? `${myRank?.rank} ${username}` : '';

  const leaderboardUserData =
    userRank && !leaderboardUser.includes(userRank)
      ? [...leaderboardUser, userRank]
      : leaderboardUser;

  const { currentTextIndex, isFallingIn, handleTextAnimationEnd } =
    useAnimatedText(leaderboardUserData);
  const date = useSyncTimer(getCountTime(data, status), 60 * 1000);
  if (!data) return null;

  const url = generatePath(Paths.TournamentDetails, { id: data._id });

  return (
    <Paper
      {...props}
      {...(!readonly && {
        component: MyLink,
        href: url,
        underline: 'none',
        display: 'block',
      })}
      sx={{ overflow: 'hidden', ...props?.sx }}>
      <AspectRatio ratio={9 / 16}>
        {useNexImage && nextImageProps ? (
          <Image {...nextImageProps} />
        ) : (
          <Box
            component="img"
            sx={{ objectFit: 'cover', zIndex: -100 }}
            width="100%"
            height="100%"
            src={data.banner}
            alt={data.name}
          />
        )}

        <Stack>
          <Box
            sx={{
              position: 'absolute',
              left: '8px',
              width: 'calc(100% - 96px)',
            }}>
            <Typography
              className={subFont.className}
              sx={{
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 700,
                color: 'custom.content.4',
                my: 1,
              }}>
              {data.name}
            </Typography>
            <Prize data={data} />
          </Box>
          <TournamentBadge
            sx={{ position: 'absolute', right: '8px' }}
            status={status}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            position: 'absolute',
            left: '8px',
            bottom: '8px',
          }}>
          {status === TOUR_TIME_STATUS.UPCOMING ? (
            <Typography
              className={subFont.className}
              sx={{
                fontSize: 14,
                lineHeight: 1,
                fontWeight: 500,
                textDecoration: 'underline',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}>
              <Trans id="tournaments.remind_me">Remind me</Trans>
              <TournamentsEmail fill="white" />
            </Typography>
          ) : myRank?.rank && status === TOUR_TIME_STATUS.ENDED ? (
            <Typography
              className={subFont.className}
              sx={{ fontSize: 14, lineHeight: 1, fontWeight: 500 }}>
              <Trans id="tournaments.you_came">
                You came {ordinalSuffixOf(myRank?.rank)}
              </Trans>
            </Typography>
          ) : (
            <Box>
              <AnimatedTypography
                text={leaderboardUserData[currentTextIndex]}
                isFallingIn={isFallingIn}
                onAnimationEnd={handleTextAnimationEnd}
                rank={currentTextIndex + 1}
              />
            </Box>
          )}
        </Stack>
        <TournamentTimer date={date} status={status} />
      </AspectRatio>
    </Paper>
  );
};

export const Prize: React.FCC<
  { data: _TournamentItem; text?: React.ReactNode } & PaperProps
> = ({
  data,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  text = <Trans id="tournaments.prize_pool">Prize Pool</Trans>,
  ...paperProps
}) => {
  const { FORMAT } = useLocale();
  return (
    <Paper
      {...paperProps}
      component={Stack}
      spacing={0.5}
      variant="outlined"
      sx={{
        borderRadius: 2,
        bgcolor: 'transparent',
        border: 'none',
        ...paperProps?.sx,
      }}>
      <Stack
        spacing={0.5}
        direction="row"
        sx={{
          alignItems: 'center',
        }}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 700, fontSize: 24, color: 'custom.content.4' }}>
          ${new BN(data.prizePool).dp(CRYPTO_DP).toFormat(FORMAT)}
        </Typography>
        {data.token && (
          <img
            src={getIconSrc(data.token.toLowerCase())}
            alt={data.token}
            width={20}
            height={20}
          />
        )}
      </Stack>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 400,
          color: '#A5A5A5',
          marginTop: '0 !important',
        }}>
        {text}
      </Typography>
    </Paper>
  );
};
